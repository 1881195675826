<template lang="pug">
  modal.user-menu-mobile(v-show="mounted", :styles="styles", transition="modal", :pivotY="0", :max-width="1200", ref="modal", :name="modalName", :adaptive="true", @closed="enableScrolling")
    .modal-header
      .close-button.is-flex(@click="onCloseHandler")
        span {{ $t('common.close') }}
        icon(iconName="close", iconColor="#2e2e2e")
    .modal-body
      sign-in-form(type="user-menu", v-if="signIn", @change="onFormChangeHandler")
      sign-up-form(type="user-menu", v-else, @change="onFormChangeHandler")
</template>

<script>
import { FadeTransition } from 'vue2-transitions'
import modalMixin from '~~/mixins/modalMixin'

export default {
  name: 'UserMenuNonAuthMobileComponent',

  components: {
    FadeTransition,
    SignUpForm: () => import('~~/components/Auth/SignUpForm'),
    SignInForm: () => import('~~/components/Auth/SignInForm')
  },

  mixins: [modalMixin],

  data: () => ({
    signIn: true,
    modalEvent: 'account-menu',
    modalName: 'account-menu',
    styles:
      'min-height: 100vh; border-radius: 0; background-color: #f5f5f5; z-index: 1000'
  }),

  watch: {
    '$route.name'() {
      this.onCloseHandler()
    }
  },

  methods: {
    onFormChangeHandler() {
      this.signIn = !this.signIn
    }
  }
}
</script>

<style lang="sass" scoped>
@include fade-transition('sign-menus')

.user-menu-mobile
  &.v--modal-overlay
    z-index: 2000
  ::v-deep
    .v--modal-box
      overflow: auto
  .modal-header
    position: relative
    height: 30px
    .close-button
      cursor: pointer
      position: absolute
      top: 10px
      right: 10px
      align-items: center
      text-transform: uppercase
      svg
        margin-left: 5px
        height: 15px
        width: 15px
  .modal-body
    padding: 20px
    &-wrapper
      background-color: $secondary-bg-color
</style>
